import React, { useEffect, useState } from "react";
import { Field } from "formik";
import axios from "axios";
import { BASE_URL, NODE_API_URL } from "../../../../../env_config";
import makeAnimated from 'react-select/animated';
import Cookies from "js-cookie";
import Select from 'react-select';

const animatedComponents = makeAnimated();

export const Location = ({ language, formik }) => {
    const [locationOptions, setLocationOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        axios({
            method: "post",
            url: `${NODE_API_URL}/AUS/get-all-location`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
            },
            data: {
                "diamondType": "N"
            }
        })
            .then(function (res) {
                if (res && res.data.success && res.data.data) {
                    const options = res.data.data.map(item => ({
                        value: item.country,
                        label: item.country.toUpperCase()
                    }));

                    const sortedOptions = options.sort((a, b) => {
                        if (a.label === "OTHER") return 1;
                        if (b.label === "OTHER") return -1;
                        return 0;
                    });

                    setLocationOptions(sortedOptions);
                }
            })
            .catch(function (res) {
                if (res?.response?.data?.message === "UnAuthorized!") {
                    var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                        if (cookies[i] && !cookies[i].includes("view")) {
                            var equals = cookies[i].indexOf("=");
                            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        }
                    }
                    window.location.replace(`${BASE_URL}/logout`);
                }
            });
    }, []);

    const filterOptions = (options, inputValue) => {
        return options.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.LOCATION_LOCATION}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-9 d-flex flex-wrap">
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={filterOptions(locationOptions, inputValue)}
                                onInputChange={setInputValue}
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions.map(option => option.value);
                                    formik.setFieldValue("Location", selectedValues);
                                }}
                                placeholder="Select Location"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        width: '300px',
                                        border: 'none', // Remove border
                                        color: 'black', // Text color black
                                        // height: '40px', // Set height
                                        boxShadow: state.isFocused ? '0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12)' : null, // Box shadow on focus
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '150px',
                                        overflowY: 'auto',
                                    }),
                                    option: (provided, { isFocused }) => ({
                                        ...provided,
                                        textTransform: 'uppercase',
                                        backgroundColor: isFocused ? '#e0e0e0' : null,
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};